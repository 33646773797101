<template>
	<div>
		<!-- 内容 -->
		<div class="orderdetailsbox">
			<el-card>
				<div class="global-search-box">
					<div class="search-content">
						<div class="search-item">
							<label class="search-label">关键词：</label>
							<el-input  v-model="commentsearchtext" placeholder="请输入商品标题"></el-input>
						</div>
						<div class="search-item">
							<label class="search-label">回复状态：</label>
							<el-select v-model="replytype" placeholder="请选择">
								<el-option v-for="(item,index) in replytypelist" :key="item.value"
									:label="item.label" :value="item.value"></el-option>
							</el-select>
						</div>
						<div class="search-item">
							<label class="search-label">评价时间：</label>
							<el-date-picker v-model="valuetimes" type="datetimerange"
							 	start-placeholder="开始日期" format="yyyy-MM-dd" :default-time="['00:00:00', '23:59:59']"
							 	end-placeholder="结束日期">
							 </el-date-picker>
						</div>
					</div>
					<div class="serach-buttom-button">
					    <el-button type="primary" @click="commentseacrh">搜索</el-button>
					    <el-button @click="resetting">重置条件</el-button>
					</div>
				</div>
			</el-card>
			<div class="batchtable">
				<div class="commtab">
					<el-table :data="tableData" style="width: 100%" ref="multipleTable"
						@selection-change="singletable">
						<el-table-column width="50" type="selection"></el-table-column>
						<el-table-column width="100" prop="id" label="ID"></el-table-column>
						<el-table-column label="商品" width="340">
							<template slot-scope="scope">
								<div class="commshop clearfloat d-flex align-items-center">
									<div class="commshopimg">
										<img :src="scope.row.detail.pic" alt="" :onerror="errorImg">
									</div>
									<div class="commshoptext">
										<p>{{scope.row.detail.goods_name}}</p>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="评论" max-width="220" prop="goods_group_count" align="center">
							<template slot-scope="scope">
								<div class="commentcon">
									<div class="commentconup">
										<el-rate v-model="scope.row.score_star" disabled text-color="#ff9900"></el-rate>
									</div>
									<div class="commentconbut">
										<p>{{scope.row.content}}</p>
										<div class="clearfloat comimglist">
											<p v-for="(item,index) in scope.row.pic_list"><img :src="item" alt="" :onerror="errorImg"></p>
										</div>
									</div>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="用户" prop="created_at" max-width="220" align="center">
							<template slot-scope="scope">
								<div class="commentcon">
									<p>{{scope.row.user?.nickname}}</p>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="评论时间" prop="created_at" max-width="340" align="center">
							<template slot-scope="scope">
								<div class="commentcon">
									<p>{{scope.row.created_at}}</p>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="状态" prop="created_at" max-width="200" align="center">
							<template slot-scope="scope">
								<div class="processtype">
									<p v-if="scope.row.is_review == 0">未审核</p>
									<p class="yiprocess" v-if="scope.row.is_review == 1">已审核</p>
									<p class="zprocess" v-if="scope.row.is_review == 2">审核中</p>
									<p class="weiprocess"  v-if="scope.row.is_review == 3">审核不通过</p>
								</div>
							</template>
						</el-table-column>
						<el-table-column label="操作" prop="created_at" fixed="right" width="200">
							<template slot-scope="scope">
								<div class="setoperate setoperateed">
									<button @click="loopcomments(scope.row.id)" v-if="hasPerm(['order.comment.show'])">查看</button> 
									<button @click="commentshide(scope.row.id,scope.row.is_hide)" v-if="scope.row.is_hide == 0 && hasPerm(['order.comment.hidden'])">{{scope.row.is_hide == 0 ? "隐藏" : "显示"}}</button> 
									<button @click="commentdelect(scope.row.id)"  v-if="hasPerm(['order.comment.delete'])">删除</button>
								</div>
							</template>
						</el-table-column>
						<template slot="empty">
							<div class="empty_data">
								<p>暂无数据</p>
							</div>
						</template>
					</el-table>
				</div>
				<div class="commentbuttom clearfloat">
					<div class="orderlastbutleft clearfloat">
						<p><span class="ordercheck"><el-checkbox v-model="all_checked" @change="allchange">当前页全选</el-checkbox></span></p>
						<el-button class="grounding" @click="commentapprove()" v-if="hasPerm(['order.comment.review'])">审核通过</el-button>
						<el-button class="grounding" @click="commentfailed()" v-if="hasPerm(['order.comment.review'])">审核不通过</el-button>
						<el-button class="grounding" @click="commentreply()" v-if="hasPerm(['order.comment.reply'])">批量回复</el-button>
						<el-button class="grounding" @click="commentdelectcon()" v-if="hasPerm(['order.comment.delete'])">批量删除</el-button>
					</div>
					<div class="orderlastbutright" style="display: block;float: right: ;;">
						<el-pagination @size-change="handleSizeChange" hide-on-single-page
							@current-change="handleCurrentChange" :current-page.sync="currentPage"
							:page-sizes="[10, 20, 30, 40]" :page-size="eachPage"
							layout="sizes, prev, pager, next, jumper" :pager-count="5" :total="total_entry">
						</el-pagination>
					</div>
				</div>
			</div>
		</div>
		
		<!-- 批量回复评论 -->
		<el-dialog title="评论回复" class="commentc" :visible.sync="commentctype">
			<div class="commentcbox">
				<el-input type="textarea" v-model.trim="conmmentext" maxlength="255" show-word-limit resize="none"
					class="textarea-box" placeholder="回复内容(最多输入255字)"></el-input>
			</div>
			<div slot="footer" class="dialog-footer">
				<el-button @click="closeattrubute()">取 消</el-button>
				<el-button class="addressqr" @click="commentdetermine()">确 定</el-button>
			</div>
		</el-dialog>
	</div>
</template>

<script>
	export default {
		inject: ["reload"],
		data() {
			return {
				errorImg:'this.src="' + require("../../assets/images/lodingsb.svg") + '"', //图片加载失败
				commentsearchtext: '',
				replytypelist: [{
					label: '未回复',
					value: '0',
				},
				{
					label: '已回复',
					value: '1',
				}],
				all_checked: false,//全选状态
				currentPage: 1,
				eachPage: 10,
				total_entry: 0,
				replytype:'',//回复状态
				valuetimes:'',//评价时间
				sifttype:'',//精选状态
				tableData: [],
				idlist:[],
				multipleSelection: [], //表格中选中的数据
				commentctype: false,
				conmmentext:'',
			};
		},
		created() {
		},
		mounted() {
			this.commentapi();
		},
		methods: {
			commentapi(){
				let data = {
					search: [],
					order: [{
						key: "id",
						value: "desc"
					}],
					page: this.currentPage,
					limit: this.eachPage
				};
				if (this.commentsearchtext) {
					data.search.push({"key": "goods_name","value": this.commentsearchtext,"op": "search"});
				}
				if(this.replytype){
					data.search.push({"key": "is_reply","value": this.replytype,"op": "search"});
				}
				if (this.valuetimes) {
					data.search.push({"key": "created_at","value": [this.common.timeStampTurnTime(this.valuetimes[0]), this.common.timeStampTurnTime(this.valuetimes[1])],"op": "between"});
				}
				// //console.log(data)
				this.$get(this.$apis.evaluate,data).then(res => {
					if (res.code == 200) {
						this.tableData = res.data.list;
						this.total_entry = res.data.total;
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 隐藏
			commentshide(id,hides){
				this.idlist.push(id);
				if(hides == 1){
					hides = 0
				}else{
					hides = 1
				}
				let data = {
					is_hide: hides,
					id: this.idlist
				};
				this.$put(this.$apis.commenthide,data).then(res => {
					if (res.code == 200) {
						this.$message({
							showClose: true,
							message: '设置成功',
							type: 'success',
							offset: 200,
							duration: 1000,
							onClose :()=>{
								this.commentapi();
								this.idlist = [];
							}
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 单个删除
			commentdelect(id){
				this.idlist.push(id);
				this.commentdelectapi(this.idlist);
			},
			// 批量删除
			commentdelectcon(){
				this.idlist = [];
				if (!this.multipleSelection.length) {
					this.$message({
						showClose: true,
						message: '请选择需要批量通过审核的评论',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.idlist.push(this.multipleSelection[i].id)
				}
				this.commentdelectapi(this.idlist);
			},
			commentdelectapi(id){
				let data = {
					id: id
				};
				this.$put(this.$apis.commentdelect,data).then(res => {
					if (res.code == 200) {
						this.$message({
							showClose: true,
							message: '删除成功',
							type: 'success',
							offset: 200,
							duration: 1000,
							onClose :()=>{
								this.commentapi();
								this.idlist = [];
							}
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			// 审核通过
			commentapprove(){
				this.idlist = [];
				if (!this.multipleSelection.length) {
					this.$message({
						showClose: true,
						message: '请选择需要批量通过审核的评论',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.idlist.push(this.multipleSelection[i].id)
				}
				let texted = '是否审核通过评价?';
				this.commentreviewapi(this.idlist,1,texted);
			},
			// 审核不通过
			commentfailed(){
				this.idlist = [];
				if (!this.multipleSelection.length) {
					this.$message({
						showClose: true,
						message: '请选择需要批量不通过审核的评论',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.idlist.push(this.multipleSelection[i].id)
				}
				let texted = '是否审核不通过评价?';
				this.commentreviewapi(this.idlist,3,texted);
			},
			commentreviewapi(id,typed,text){
				let data = {
					type:typed,
					id: id
				};
				
				this.$confirm(text, '提示', {
					confirmButtonText: '确定',
					cancelButtonText: '取消',
					type: 'warning'
				}).then(() => {
					this.$put(this.$apis.commentreview,data).then(res => {
						if (res.code == 200) {
							this.$message({
								showClose: true,
								message: '设置成功',
								type: 'success',
								offset: 200,
								duration: 1000,
								onClose :()=>{
									this.commentapi();
									this.idlist = [];
								}
							});
						} else {
							this.common.message(this, res.message);
						}
					}).catch(err => {
						// //console.log(err)
						this.common.message(this, err.message);
					});
				}).catch(() => {
					// this.$message({
					// 	type: 'info',
					// 	message: '已取消删除'
					// });
				});
			},
			// 批量回复
			commentreply(){
				this.idlist = [];
				if (!this.multipleSelection.length) {
					this.$message({
						showClose: true,
						message: '请选择需要批量回复的评论',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				this.commentctype = true;
				for (let i = 0; i < this.multipleSelection.length; i++) {
					this.idlist.push(this.multipleSelection[i].id)
				}
			},
			commentdetermine(){
				if (!this.conmmentext) {
					this.$message({
						showClose: true,
						message: '请输入回复内容',
						type: 'warning',
						offset: 200,
						duration: 1000
					});
					return false;
				}
				this.commentreplyapi(this.idlist);
			},
			commentreplyapi(id){
				let data = {
					content:this.conmmentext,
					id: id
				};
				this.$put(this.$apis.commentreply,data).then(res => {
					if (res.code == 200) {
						this.$message({
							showClose: true,
							message: '回复成功',
							type: 'success',
							offset: 200,
							duration: 1000,
							onClose :()=>{
								this.commentapi();
								this.commentctype = false;
								this.idlist = [];
							}
						});
					} else {
						this.common.message(this, res.message);
					}
				}).catch(err => {
					// //console.log(err)
					this.common.message(this, err.message);
				});
			},
			loopcomments(id){
				this.$router.push({
					path: '/order/order_commentdetail',
					query: {
						id: id
					}
				});
			},
			// 搜索
			commentseacrh(){
				this.currentPage = 1;
				this.commentapi();
			},
			// 重置
			resetting() {
				this.commentsearchtext = '';
				this.replytype = '';
				this.valuetimes = '';
				this.commentapi();
			},
			// 选择所有商品
			allchange(value) {
				if (!value) {
					this.$refs.multipleTable.clearSelection();
				} else {
					this.$refs.multipleTable.toggleAllSelection();
				}
				this.all_checked = value;
			},
			// 单个表格被选中
			singletable(val) {
				this.multipleSelection = val;
				if (val.length == this.tableData.length) {
					this.all_checked = true;
				} else {
					this.all_checked = false;
				}
			},
			handleSizeChange(val) {
				this.eachPage = val;
				this.commentapi();
			},
			handleCurrentChange(val) {
				this.currentPage = val;
				this.common.backTop();
				this.commentapi();
			},
		}
	};
</script>

<style scoped>
	@import url("css/order.css");
	.batchtable{margin-top: 20px;}
	.setoperateed{text-align: left;margin-left: 10px;}
	.setoperate button{display: inline-block;font-size: 14px;color: var(--fontColor,#fb6638);position: relative;margin-right: 12px;
	background: transparent;border: 0;}
	.setoperate button:last-child{margin-right: 0;}
	.setoperate button::before{content: "";width: 1px;height: 12px;background: var(--fontColor,#fb6638);position: absolute;top: 4px;right: -7px;}
	.setoperate button:last-child::before{display: none;}
	.comimglist {
		display: flex;
		justify-content: center;
		align-items: center;
		flex-wrap: wrap;
	}
	.comimglist p{float: left;width: 50px;height: 50px;margin-right: 5px;margin-bottom: 5px;}
	.comimglist p img{display: block;width: 100%;height: 100%;object-fit: cover;}
	.grounding{height: 32px;padding-top: 0;padding-bottom: 0;margin-left: 8px;padding: 12px 10px !important;line-height: 0;}
	.commentc /deep/ .el-dialog {width: 520px !important;border-radius: 4px !important;}
	.commentcbox{width: 100%;padding: 20px;box-sizing: border-box;}
	.commentcbox .el-textarea{width: 100% !important;}
	.commentcbox /deep/ .el-textarea .el-textarea__inner{height: 100px;}
</style>